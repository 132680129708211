<template>
  <div v-if="istrue" class="self">
    <div class="pos">
      <div
        v-if="info.avatar == null"
        class="t1"
        :style="{
          background: 'rgb(214 214 214)',
          'background-size': 'cover',
        }"
      ></div>
      <div
        v-else
        class="t1"
        :style="{
          background: 'url(' + info.avatar + ')',
          'background-size': 'cover',
        }"
      ></div>
      <div class="abs" style="top: 50px; left: 0; width: 90%; left: 5%">
        <div class="flex_between">
          <div class="flex_column flex_items">
            <van-image
              v-if="info.avatar == null"
              fit="contain"
              :src="require('@/images/home/mtx.png')"
              class=""
              width="70"
              height="70"
              round
            ></van-image>
            <van-image
              v-else
              fit="contain"
              :src="info.avatar"
              class=""
              width="70"
              height="70"
              round
            ></van-image>
            <p class="mt10 f18 bold" style="color: #fff">{{ info.nickName }}</p>
          </div>
          <div v-if="from=='person'" class="flex_start flex_items">
            <van-image
              fit="contain"
              :src="require('@/images/home/erweima.png')"
              class="mr20"
              width="22"
              @click="qr"
            ></van-image>
            <van-button
              type="default"
              size="small"
              style="
                height: 32px;
                height: 32px;
                background: none;
                color: #fff;
                padding: 0 20px;
                border-radius: 4px;
              "
              @click="profile"
              >Profile</van-button
            >
          </div>
        </div>
        <div class="f12 flex_column mt15" style="color: #fff">
          <p>Freelancers | occasional foaming</p>
          <p class="mt10">Join pixgram 365 days</p>
          <div class="mt10 flex_start flex_items">
            <div
              class="plr10 ptb5 mr10"
              style="
                background: linear-gradient(89deg, #ffead6 0%, #ffe0c2 100%);
                opacity: 1;
                border-radius: 3px;
                color: #f9671e;
              "
            >
              {{ info.balance }} PIX
            </div>
            <div
              class="flex_center flex_items p5 mr10"
              style="background: rgba(0, 0, 0, 0.8); border-radius: 3px"
            >
              <van-image
                fit="contain"
                :src="require('@/images/home/women.png')"
                class=""
                width="13"
                v-if="info.gender == 2"
              ></van-image>
              <van-image
                fit="contain"
                :src="require('@/images/home/man.png')"
                class=""
                width="13"
                v-else
              ></van-image>
            </div>
            <!-- <div
              class="p5 f12"
              style="
                background: rgba(0, 0, 0, 0.8);
                border-radius: 3px;
                color: #c8c8c8;
              "
            >
              Londan
            </div> -->
          </div>
        </div>
        <div class="flex_start flex_item mt15 f14" style="color: #fff">
          <span class="mr20">{{ info.following_count }}&nbsp;Following</span>
          <span class="mr20">{{ info.transmit_count }}&nbsp;Followers</span>
        </div>
      </div>
    </div>
    <!-- t2 -->
    <div>
      <van-tabs
        v-model="active"
        sticky
        swipeable
        title-active-color="#000000"
        title-inactive-color="#8E8E8E"
        color="#FF7900"
        animated
        @click="tabchange"
      >
        <van-tab :title="info.post_count + ' Posts'" class="p5 bgwhite">
          <selfpost :nickname="info.nickName" :author="info.name"></selfpost>
        </van-tab>
        <van-tab :title="info.transmit_count + ' Reposts'" class="p5 bgwhite">
          <selfrepost
            :nickname="info.nickName"
            :author="info.name"
          ></selfrepost>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>
<script>
import { Toast } from "vant";
import selfpost from "@/components/selfpost.vue";
import selfrepost from "@/components/selfrepost.vue";
export default {
  data() {
    return {
      active: 0,
      info: {},
      istrue: false,
      author: "",
      from: "",
    };
  },
  components: {
    selfpost,
    selfrepost,
  },
  created() {
    this.author = this.$route.params.author;
    this.from = this.$route.params.from;
    this.getData();
  },
  methods: {
    getData() {
      Toast.loading({
        message: "Loading...",
        forbidClick: true,
      });
      this.util
        .energy(this.HOST + "/account/user/" + localStorage.getItem("account"), {
          appVersion: "1.0.6",
          country: "cn",
          deviceName: "iPhone1",
          language: "en",
          osVersion: "14.7.1",
          platform: 2,
          timestamp: Date.parse(new Date()),
          timezone: "Asia/Shanghai",
        })
        .then((res) => {
          this.info = res.data.accountInfo;
          this.istrue = true;
        });
    },
    tabchange(val) {
      console.log(val);
    },
    qr() {
      this.$router.push({
        name: "qrcode",
        params: {
          headerurl: this.info.avatar,
          name: this.info.nickName,
        },
      });
    },
    profile() {
      this.$router.push({
        name: "profile",
        params: {
          headerurl: this.info.avatar,
          name: this.info.nickName,
          gender: this.info.gender,
          info: JSON.parse(this.info.json_metadata),
        },
      });
    },
  },
  beforeRouteLeave(to, from, next) {
    if (to.name == "secondflow") {
      to.meta.isback = false;
    }
    next(); //不可缺少
  },
};
</script>
<style>
.self .t1 {
  filter: blur(2px);
  width: 100%;
  height: 300px;
  background-size: cover !important;
}
.self .van-tabs__wrap {
  border-bottom: 1px solid #e8e8e8;
}
</style>